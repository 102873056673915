import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '../../../common/button/button';
import PhoneIcon from '@mui/icons-material/Phone';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendProjectEnquiry } from '../../../reduxSlices/enquirySlice';

import '../propertyPage.css';

const textFieldStyles = {
  width: '90%',
  height: '20px',
  marginBottom: '30px',
  '& fieldset': {
    borderColor: '#E7B554',
  },
  '&:hover fieldset': {
    borderColor: '#E7B554',
  },
  '&.Mui-focused fieldset': {
    borderColor: '#E7B554',
  },
  '& .MuiOutlinedInput-input': {
    height: '10px',
  },
};

const CustomTextFieldWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '70%',
});

function PropertyHeaderContact(name) {
  const apiUrl = process.env.NEXT_PUBLIC_SITE_KEY;
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    projectName: name.name
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);

  // useEffect(() => {
  //   window.grecaptcha.execute(process.env.NEXT_PUBLIC_SITE_KEY, { action: 'submit' }).then((token) => {
  //     // Add the token to your form data
  //   });
  // },[]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (recaptchaRef.current) {
      try {
        const token = await recaptchaRef.current.executeAsync(); // Execute reCAPTCHA
        setCaptchaToken(token); // Set the token in state

        // Dispatch the form data with the CAPTCHA token
        // Dispatch the form data with the CAPTCHA token
        const result = await dispatch(sendProjectEnquiry({ ...formData, captchaToken: token }));
        // console.log(result);
        // Check the result of the dispatch
        if (result.payload && result.payload.status === 'success') {
          // Redirect to Thank You page if successful
          window.location.href = '/thank-you';
        } else {
          // Handle the case where the form submission fails
          alert('Form submission failed. Please try again.');
        }
      } catch (error) {
        alert('Failed to execute reCAPTCHA. Please try again.');
      }
    } else {
      alert('reCAPTCHA not loaded properly.');
    }
  };

  return (
    <div className="propertyPageHeaderContactUs">
      <h4>GET IN TOUCH WITH OUR EXPERTS!</h4>
      <div className="propertyPageHeaderContactUsPhoneFlex">
        <PhoneIcon sx={{ color: 'green' }} />
        <p style={{ margin: '0' }}>+ 91 86 9800 9900</p>
      </div>
      <form onSubmit={handleSubmit} className="propertyTopFormContact">
        <CustomTextFieldWrapper className='propertyTopForm'>
          <TextField
            name="name"
            id="outlined-basic"
            label="Name"
            type='text'
            variant="standard"
            sx={textFieldStyles}
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            name="phoneNumber"
            id="outlined-basic"
            type='number'
            label="Phone Number"
            variant="standard"
            sx={textFieldStyles}
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <TextField
            name="email"
            id="outlined-basic"
            type='email'
            label="E-mail"
            variant="standard"
            sx={textFieldStyles}
            value={formData.email}
            onChange={handleChange}
          />
        </CustomTextFieldWrapper>
        <div className='recaptcha-container'>
        <ReCAPTCHA
            sitekey={`6LfrSTUqAAAAAOy2-j9cNvTIujOI5GKjtMVsn2Uk`}
            size="invisible"
            ref={recaptchaRef} // Reference to the ReCAPTCHA component
          />
        </div>
        <p className="propertyPageHeaderContactUsDisclaimer">
          * By submitting, I accept Inframantra{' '}
          <a href="#"> Terms & Conditions </a> and{' '}
          <a href="#"> Privacy Policy</a>
        </p>
        <Button btnText="Contact now" width="250px" type="submit" />
      </form>

      <div className="propertyPageHeaderContactIconContainer">
        <p>Why Inframantra is Your Trusted Partner!</p>
        <div className="propertyPageHeaderContactIconFlex">
          <div className="propertyPageHeaderContactIcon">
            <img
              style={{ width: '2vw' }}
              src="https://inframantra.blr1.cdn.digitaloceanspaces.com/propertyPage/brokerage%20free%202.svg"
              alt="conatctIcon "
            />
            <p>Zero Brokerage</p>
          </div>
          <div className="propertyPageHeaderContactIcon">
            <img
              style={{ width: '2vw' }}
              src="https://inframantra.blr1.cdn.digitaloceanspaces.com/propertyPage/exclusive%20inventory.svg"
              alt="conatctIcon "
            />
            <p>Exclusive Inventory</p>
          </div>
          <div className="propertyPageHeaderContactIcon">
            <img
              style={{ width: '2vw' }}
              src="https://inframantra.blr1.cdn.digitaloceanspaces.com/propertyPage/lowest%20price%20assurance.svg"
              alt="conatctIcon "
            />
            <p>Lowest Price</p>
          </div>
          <div className="propertyPageHeaderContactIcon">
            <img
              style={{ width: '2vw' }}
              src="https://inframantra.blr1.cdn.digitaloceanspaces.com/propertyPage/comprehensive%20support.svg"
              alt="conatctIcon "
            />
            <p>Comprehensive Support</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyHeaderContact;
