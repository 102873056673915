import React, { useState, useRef } from 'react';
import PropertyWrapper from './propertyWrapper';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Button from '../../../common/button/button';
import FormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '../../../utils/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { sendProjectEnquiry } from '../../../reduxSlices/enquirySlice.js';
import Alert from '@mui/material/Alert';
import { useMuiSnackbar } from '../../../components/UI/useMuiSnackbar.jsx';
import ReCAPTCHA from 'react-google-recaptcha';
import '../propertyPage.css'; // Ensure to include this for custom styles

const contactFormStyles = {
  textField: {
    marginBottom: '10px',
  },
  checkBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '30px',
  },
};

function PropertyPageFloatingContact({ name }) {
  const isDesktop = useMediaQuery(768);
  const dispatch = useDispatch();
  const { showSnackbar } = useMuiSnackbar();
  const { data, loading, error } = useSelector(state => state.enquiries);
  const [formData, setFormData] = useState({
    projectName: name || '',
    name: '',
    email: '',
    phoneNumber: '',
    configuration: '',
  });
  const [selectedConfigurations, setSelectedConfigurations] = useState([]);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState('');
  const recaptchaRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedConfigurations((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (recaptchaRef.current) {
      try {
        const token = await recaptchaRef.current.executeAsync(); // Execute reCAPTCHA
        setCaptchaToken(token); // Set the token in state

        // Dispatch the form data with the CAPTCHA token
        const result = await dispatch( sendProjectEnquiry({
          ...formData,
          configuration: selectedConfigurations.join(', '),
          captchaToken: token,
        }));
        // console.log(result);
        // Check the result of the dispatch
        if (result.payload && result.payload.status === 'success') {
          // Redirect to Thank You page if successful
          showSnackbar('Enquiry sent successfully', 'success');
          window.location.href = '/thank-you';
        } else {
          // Handle the case where the form submission fails
          showSnackbar(`Failed to send enquiry ${result.payload.error}`, 'error');
        }
      } catch (error) {
        showSnackbar(`Failed to send enquiry ${error}`, 'error');
      }
    } else {
      setCaptchaError('reCAPTCHA not loaded properly.');
    }
  };

  return (
    <PropertyWrapper
      children={
        <form className="aboutProjectWrapper" onSubmit={handleSubmit}>
          <div className="propertyPageFloatingContactHeaderWrapper">
            <p>Get Expert Advice and Information for</p>
            <p
              style={{
                color: '#e7b554',
                fontWeight: '700',
                fontSize: '20px',
                marginBottom: '10px',
              }}
            >
              {name}
            </p>
          </div>
          <div className="propertyPageFloatingContactWrapper">
            <TextField
              id="name"
              name="name"
              label="Name"
              variant="standard"
              fullWidth={true}
              sx={contactFormStyles.textField}
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              variant="standard"
              fullWidth={true}
              sx={contactFormStyles.textField}
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <TextField
              id="email"
              name="email"
              label="Email Address"
              variant="standard"
              fullWidth={true}
              sx={contactFormStyles.textField}
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="propertyPageFloatingContactWrapper">
            <p>I’m looking into</p>
            <FormGroup sx={contactFormStyles.checkBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    value="2BHK"
                    checked={selectedConfigurations.includes('2BHK')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="2BHK"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    value="3BHK"
                    checked={selectedConfigurations.includes('3BHK')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="3BHK"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    value="4BHK"
                    checked={selectedConfigurations.includes('4BHK')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="4BHK"
                sx={{ marginLeft: '0px', marginRight: '0px' }}
              />
            </FormGroup>
            <div className="recaptcha-container">
              <ReCAPTCHA
                sitekey={`6LfrSTUqAAAAAOy2-j9cNvTIujOI5GKjtMVsn2Uk`}
                size="invisible"
                ref={recaptchaRef} // Reference to the ReCAPTCHA component
                onChange={handleCaptchaChange}
              />
            </div>
            {captchaError && <p className="recaptcha-error">{captchaError}</p>}
            <Button
              type="submit"
              width={isDesktop ? '15vw' : '60vw'}
              fontWeight="700"
              otherStyles={{ marginBottom: '30px' }}
              btnText="Contact Now"
            />
          </div>
        </form>
      }
    />
  );
}

export default PropertyPageFloatingContact;
