import React, { useState, useRef, useEffect } from 'react';
import NavDropdown from './navDropdown/navDropdown';
import PropertyListingSearch from './propertyListingSearch/propertyListingSearch';
import ListingNavSortBy from '../../../components/listingNavSortBy/listingNavSortBy';
import {
  CityChangeDropdown,
  PropertyTypeDropdown,
  PriceRangeDropdown,
  ProjectStatusDropdown,
} from './propertyListingSearch/propertyListingDropdownComponents/propertyListingDropdownComponent';
import { useParams, useNavigate } from 'react-router-dom';
import './propertyListingDesktopNav.css';

function PropertyListingDesktopNav() {
  const { name } = useParams();
  const navigate = useNavigate();
  const [currentCitySearch, setCurrentCitySearch] = useState(name);

  // useEffect(() => {
  //   if (currentCitySearch === 'Gurgaon' || 'Pune' || 'Noida' || 'Delhi') {
  //     navigate(`/listing`);
  //   }else{
  //     navigate(`/listing?city=${name}`);
  //   }
  // }, [navigate, currentCitySearch]);

  return (
    <div className="propertyListingDesktopNav">
      <NavDropdown
        bgd={true}
        currentlySelectedCity={setCurrentCitySearch}
        title={currentCitySearch}
        width="5vw"
      >
        <CityChangeDropdown handleCityChange={setCurrentCitySearch} />
      </NavDropdown>
      <PropertyListingSearch location={currentCitySearch} />
      <NavDropdown width="11vw" title="Property Type" marginLeft="2%">
        <PropertyTypeDropdown />
      </NavDropdown>
      <NavDropdown width="10vw" title="Price Range">
        <PriceRangeDropdown />
      </NavDropdown>
      <NavDropdown width="13vw" title="Project Status">
        <ProjectStatusDropdown />
      </NavDropdown>
      <ListingNavSortBy />
    </div>
  );
}

export default PropertyListingDesktopNav;
