import React, { useState } from 'react';
import { Navigation, Thumbs, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css';

const SwiperComponent = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        <SwiperSlide>
          <div className="swiper-slide-container">Slide 1</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-container">Slide 2</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-container">Slide 3</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-container">Slide 4</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-container">Slide 5</div>
        </SwiperSlide>
      </Swiper>
      <Swiper
        // onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        slideToClickedSlide={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="swiper-slide-container">Slide 1</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-container">Slide 2</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-container">Slide 3</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-container">Slide 4</div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-container">Slide 5</div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
