import React from "react";
import thankYouLogo from "../../assets/images/thank-you-logo.png";
import './thankyou.css'
import { useNavigate } from "react-router-dom";
import NavigationBar from "../../components/navigationBar/navigationBar";

const ThankYou = () => {
    const navigate = useNavigate();
    const logoClickHandler = () => {
        navigate('/');
      };
    return (
        <>
            <NavigationBar />
            <div className="thankYouWrapper">
                <div className="thankYouContainer">
                    <div className="thankYouImage">
                        <img src={thankYouLogo} alt="logo" />
                    </div>
                    <h1>Thank You</h1>
                    <p> We’ve received your message and our team will get back to you shortly.</p>
                    <button onClick={logoClickHandler}>Home Page</button>
                </div>
            </div>
        </>
    );
}


export default ThankYou;