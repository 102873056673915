import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CustomArrowIcon from '../../common/arrowIcon/arrowIcon';
import { propertyData } from '../../dummyData';
import { useNavigate } from 'react-router-dom';
import './featuredProperties.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const btnStyles = {
  width: '60%',
  borderRadius: '10px',
  backgroundColor: '#E7B554',
  boxShadow: '0px 3.879px 3.879px 0px rgba(8, 8, 8, 0.25)',
  marginTop: '4%',
  fontSize: '2.5vh',
  fontWeight: '700',
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
};

function FeaturedProperties({
  title,
  location,
  description,
  secondDescription,
  price,
  area,
  configurations,
  imageGallery,
  exclusive,
  id,
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % propertyData.imageGallery.length
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);


  return (
    <div className="featuredPropertiesWrapper">
      <div className="featuredPropertiesLeftSection">
        <div className="featuredPropertiesLeftSectionBgrd">
          {imageGallery.map((img, index) => (
            <img
              key={index}
              className={`featuredPropertiesLeftSectionImages ${
                index === currentImageIndex ? 'active' : ''
              }`}
              src={img.url}
              alt="featuredProp"
            />
          ))}
        </div>
        {/* <Swiper
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000 }}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[Navigation, Autoplay]}
          className="featuredPropertiesLeftSectionBgrd"
        >
          {imageGallery.map((img, index) => (
            <SwiperSlide key={index}>
              <img
                key={index}
                className={`featuredPropertiesLeftSectionImages`}
                src={img.url}
                alt="featuredProp"
              />
            </SwiperSlide>
          ))}
        </Swiper> */}
      </div>
      <div className="featuredPropertiesRightSection">
        <div className="featuredPropertiesRightSectionMarginContainer">
          <div>
            <p className="featuredPropertiesRightSectionTitle">{title}</p>
          </div>
          <p className="featuredPropertiesRightSectionLocation">{location}</p>
          {exclusive && (
            <img
              src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/exclusivePropertyHome.png"
              className="exclusiveIcon"
              alt="infra-exclusive"
            />
          )}
          <p className="featuredPropertiesRightSectionDescription">
            {description.slice(0, 150)}
          </p>
          <p className="featuredPropertiesRightSectionDescription">
            {secondDescription.slice(150, 300)}
          </p>
          <div className="featuredPropertiesRightSectionInsightsContainer">
            <div className="featuredPropertiesRightSectionInsightsFlex">
              <p className="featuredPropertiesRightSectionInsightsKey">
                {price}
              </p>
              <p className="featuredPropertiesRightSectionInsightsValue">
                Starting Price
              </p>
            </div>
            <div className="featuredPropertiesRightSectionInsightsFlex">
              <p className="featuredPropertiesRightSectionInsightsKey">
                {area.slice(0, 4)}
              </p>
              <p className="featuredPropertiesRightSectionInsightsValue">
                Sq feet
              </p>
            </div>
            <div className="featuredPropertiesRightSectionInsightsFlex">
              <p className="featuredPropertiesRightSectionInsightsKey">
                {configurations}
              </p>
              <p className="featuredPropertiesRightSectionInsightsValue">
                Configurations
              </p>
            </div>
          </div>
          <Button
            sx={btnStyles}
            variant="contained"
            endIcon={<CustomArrowIcon lineLength={120} strokeWidth={2} />}
            onClick={() => navigate(`/property/${id}`)}
          >
            Know More
          </Button>
        </div>
      </div>
      <div className="featuredPropertiesPaginationWrapper"></div>
    </div>
  );
}

export default FeaturedProperties;
