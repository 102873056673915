import React from 'react'
// import Wrapper from "../../components/UI/Wrapper"
import PageHeader from './blogComponents/pageHeader/PageHeader'
import BlogContent from './blogIndexUI/BlogContent'
import BlogsGrid from './blogComponents/blogsGrid/BlogsGrid';
import { useParams } from 'react-router-dom';
import { allData } from './blogData';
import { blogs } from './blogAllData';
import { recentBlog } from './recentBlogs';
import Wrapper from '../../components/UI/Wrapper';


const BlogDetail = ({}) => {
    // console.log(allData);
    
    const { slug } = useParams();

    // console.log("Slug", slug);

    const blog = blogs.result.find(blog => blog.slug === slug);
    const data = {
        title: blog.name,
        ...(blog.file &&{image: blog.file.path}),
        date: blog.publish
    }
    console.log("Blog: ", blog);
    if (!blog) {
        return <div>Blog not found</div>;
    }

    return (
        <Wrapper>
        <div>
            <PageHeader classes="" date={data.date} data={data}/>
            <BlogContent detailContent={blog.description} name={blog.writer_name}  recent={recentBlog.recentBlogs}  data={data} date={blog.publish} />
            <BlogsGrid blogs={recentBlog.reletedBlogs} section_title="Latest Blogs" button='hide' />
        </div>
        </Wrapper>
    )
}

export default BlogDetail;