import { Map, AdvancedMarker, APIProvider } from '@vis.gl/react-google-maps';
import React, { useRef, useEffect } from 'react';
import { mapsData } from './mapData';

export default function Mapho({ center, zoom }) {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const footerElement = document.getElementById('footer');
      const mapElement = mapContainerRef.current;

      if (footerElement && mapElement) {
        const footerRect = footerElement.getBoundingClientRect();

        const offset = 20;

        if (footerRect.top <= window.innerHeight - offset) {
          // console.log('reached footer');
          mapElement.style.position = 'fixed';
          mapElement.style.bottom = `${footerRect.height + offset}px`;
          mapElement.style.top = '-10%';
        } else {
          mapElement.style.position = 'fixed';
          mapElement.style.top = '25%';
          mapElement.style.bottom = 'auto';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      ref={mapContainerRef}
      style={{
        width: '34vw',
        height: '40vh',
        position: 'relative',
      }}
    >
      <APIProvider apiKey={'AIzaSyDh6uhpwkkniyiztlDDWEHO7Ph_sBxuJFw'}>
        <Map
          center={center}
          zoom={zoom}
          mapId="d5fdea7c413b4d05"
          options={{
            zoomControl: true,
            scrollwheel: true,
            disableDefaultUI: false,
            mapTypeControl: true,
            fullscreenControl: true,
          }}
        >
          <Markers points={mapsData} />
        </Map>
      </APIProvider>
    </div>
  );
}

const Markers = ({ points }) => {
  return (
    <>
      {points.map((point) => (
        <AdvancedMarker
          position={{ lat: point.lat, lng: point.lng }}
          key={point.key}
        />
      ))}
    </>
  );
};
