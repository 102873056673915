import React from "react";
import { useRef, useState } from "react";
import BlogPage from "../../../assets/About us banner Phone.avif";


import "./pageHeader.css";

const PageHeader = ({ title }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <div className="pageHeader">
      <div className="pageHeaderContainer">
        <div className="pageHeaderContainerWrapper">
          <div className="pageHeaderContainerImage">
            <img src={BlogPage} alt="" />
            <div className="pageHeaderContainerWrapperTitle">
              <h1>{title}</h1>
            </div>
          </div>
          <div className="pageHeaderBlogWrappper">
              <div className="pageHeaderBlogHeader">
                <h2>Other Stories</h2>
              </div>
              <div className="pageHeaderOtherStories">
                   <div className="otherStoriesWrapper">
                      <div className="otherStoriesDescription"></div>
                   </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
