import React from "react";
import './blogHomepage.css'
import Wrapper from "../../components/UI/Wrapper";
import PageHeader from "./blogComponents/pageHeader";
import SwiperComponent from "./blogComponents/swiper";


const BlogPageMain = () => {    
    return (
        <Wrapper>
                <PageHeader title="RBI rate hike: Home loans to get dearer, impact on residential sales to be moderate" />
                <SwiperComponent />
            <h1>Blog Page Main</h1>
        </Wrapper>
    );
}

export default BlogPageMain;