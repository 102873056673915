import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPropertyByCityName } from '../services/propertyListing';

// Thunk for fetching property data by city name
export const getPropertyCityListing = createAsyncThunk(
    'property/getPropertyByCity',
    async ({ stateName }, { rejectWithValue }) => {
        try {
            const property = await getPropertyByCityName(stateName);
            return property;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    data: null,
    status: 'idle',
    error: null,
};
// Slice definition
const propertyCitySlice = createSlice({
    name: 'project',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getPropertyCityListing.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(getPropertyCityListing.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload;
          })
          .addCase(getPropertyCityListing.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          })
    },
});


export default propertyCitySlice.reducer;

// Selector function to access city data
export const selectCityData = (state) => state.propertyCity.cityData;

