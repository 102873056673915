import React, { useEffect } from "react";
import HeaderSection from "../../pages/homePage/headerSection/headerSection";
import NavigationBar from "../navigationBar/navigationBar";
import Footer from "../footer/footer";
import './wrapper.css';
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


const Wrapper = (props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const isHomePage = location.pathname === "/";

  // console.log(props);
  return (
    <>
      <Helmet htmlAttributes>
        <title>{props.title ? props.title : "InfraMantra"}</title>
        <meta name="description" content={props.description ? props.description : "InfraMantra"} />
        <meta
          name="keywords"
          content={props.keyword ? props.keyword : "INFRAMANTRA is the best real estate company in Gurugram, Noida, and Pune, offering residential, commercial properties, and apartments/flats with expert consulting."}
        />
        <meta property="og:title" content={props.title ? props.title : "InfraMantra"} />
        <meta property="og:description" content={props.description ? props.description : "InfraMantra"} />
        {/* Add other dynamic meta tags here */}
      </Helmet>
      <div className='body-wrapper'>
        <main className="main">
          <NavigationBar pageBgd={!isHomePage} />
          {props.children}
          <Footer />
        </main>
      </div>
    </>
  )
}


export default Wrapper;
