
import { GET_PROPERTY_BY_CITY_NAME } from '../reduxSlices/contants';
import axios from 'axios';


export const getPropertyByCityName = async (name) => {
    try {
        const response = await axios.get(`${GET_PROPERTY_BY_CITY_NAME}?name=${name}`);
        // console.log(response);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Error fetching property data');
    }
}