import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import RoofingOutlinedIcon from '@mui/icons-material/RoofingOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';

import { useDispatch, useSelector } from 'react-redux';
import { fetchSearchResults } from '../../reduxSlices/searchSlice';
import { fetchSearchData } from '../../reduxSlices/searchCitySlice';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';

import './searchAutoComplete.css'

import SearchIcon from '@mui/icons-material/Search';


const Root = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
    };
  font-size: 18px;
   @media (max-width: 990px) {
    width:30%;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    width: 90%;
  }
`
);

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 420px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: #E6E6E6;
  border-radius: 10px;
  padding: 8px;
  display: inline-flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center; 

  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: ${theme.palette.mode === 'dark' ? '#606060 #454545' : '#c1c1c1 #f0f0f0'
  };
  @media (max-width: 990px) {
    width: 90%;
  }
  /* For Firefox */

  &::-webkit-scrollbar {
    height: 4px; /* Height of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: ${theme.palette.mode === 'dark' ? '#454545' : '#f0f0f0'
    }; /* Color of the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.mode === 'dark' ? '#606060' : '#c1c1c1'
    }; /* Color of the scrollbar thumb */
    border-radius: 20px;
    border: 3px solid ${theme.palette.mode === 'dark' ? '#454545' : '#f0f0f0'
    }; /* Creates padding around the scrollbar thumb */
  }

  & input {
    background-color: #E6E6E6;;
    color: ${theme.palette.mode === 'dark'
      ? 'rgba(255,255,255,0.65)'
      : 'rgba(0,0,0,.85)'
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    border-radius:10px;
    flex-grow: 1; /* Allow the input to grow as needed */
    min-width: 150px; /* Adjust min-width to fit placeholder text as much as possible */
    border: 0;
    margin: 0;
    outline: 0;
     &::placeholder { 
      color: ${theme.palette.mode === 'dark'
      ? 'rgba(255,255,255,0.4)'
      : 'rgba(0,0,0,0.5)'
    }; 
      opacity: 1;
      font-size: 18px; 
    }
  }
  & .searchIcon {
  position: absolute;
  right: 25px;
  color: ${theme.palette.mode === 'dark' ? '#E7B554' : '#DA9E27'};
  cursor: pointer;
  display: none; /* Hide by default */

  @media (max-width: 767px) {
    display: block; /* Show on mobile devices */
  }
}

  @media (max-width: 768px) {
    font-size: 16px; 
    padding: 6px; 
    width: 90vw;
  }
`
);
function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 20px;
  margin: 5px;
  line-height: 22px;
  background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
    };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 5px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  min-width: max-content;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const StyledType = styled('span')(({ theme }) => ({
  fontSize: '14px',
  marginLeft: 'auto',
  color:
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.6)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
  },
}));

const Listbox = styled('ul')(
  ({ theme, listing }) => `
  width: 500px;
  margin: 20px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow-x: auto;
  overflow-y: auto;
  max-height: 225px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

 & li {
  padding: 5px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & span {
    display: flex;
    align-items: center;
  }

  & svg {
      color: #C0C0C0;
    }
}

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #ffc107;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: #ffc107;
    }
  }

  @media (max-width: 768px) {
    width: ${!listing ? '335px' : '270px'};
    margin: ${!listing ? '5px 0 0;' : '10px 0 0;'};5px 0 0;
    z-index: 1;
  }
`
);

export default function CustomizedHook({
  listing = false,
  onSearch,
  location = '',
  handleSearch
  // handleSearch,
}) {
  const dispatch = useDispatch();
  const [searchOptionsData, setSearchOptionsData]= useState('');
  const [searchOptionsAvailable, setSearchOptionsAvailable] = useState(
    [].concat(...Object.values(searchOptionsData))
  );
  const [showCount, setShowCount] = useState(100);

  useEffect(() => {
    dispatch(fetchSearchData());
  }, [dispatch]);

 
  const [selectedOptions, setSelectedOptions] = useState([]);
  const searchData = useSelector((state) => state.searchDataCity);

  useEffect(() => {
    if (searchData && searchData.results && searchData.results.data) {
      setSearchOptionsData(searchData.results.data);
    }
  }, [searchData]);
  // console.log("Available Options",searchOptionsAvailable);

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: [],
    multiple: true,
    options: searchOptionsAvailable,
    getOptionLabel: (option) => option.title,
    onChange: (event, newValue) => {
      setSelectedOptions(newValue);
      if(newValue.length >= 1){
        dispatch(fetchSearchResults(newValue.map((option) => option.title)));
      }
    },
  });

  const handleRef = (instance) => {
    setAnchorEl(instance);
    if (instance && value.length) {
      instance.scroll({
        left: instance.scrollWidth,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    
    onSearch(value);
  }, [value, onSearch]);
  useEffect(() => {
    if (value.length > 0) {
      localStorage.setItem('SearchNames', JSON.stringify(value.map(option => ({
        title: option.title,
        type: option.type
    }))));
    } else {
      localStorage.removeItem('SearchNames');
    }
  
    onSearch(value);
  }, [value, onSearch]);
  useEffect(() => {
    if (searchData && searchData.results && searchData.results.data) {
      // Filter out repeated localities and sublocalities
      const allOptions = searchData.results.data;
      const uniqueOptions = {};

      Object.keys(allOptions).forEach((city) => {
        uniqueOptions[city] = allOptions[city].filter((option, index, self) => {
          const isLocalityOrSubLocality = option.type === 'locality' || option.type === 'subLocality';
          const isUnique = self.findIndex(o => o.title === option.title && o.type === option.type) === index;

          // Exclude repeated localities or sublocalities
          return isLocalityOrSubLocality ? isUnique : true;
        });
      });

      setSearchOptionsData(uniqueOptions);
    }
  }, [searchData]);

  useEffect(() => {
    if (location !== 'Location' && searchOptionsData[location]) {
      setSearchOptionsAvailable(searchOptionsData[location].slice(0, showCount));
    } else {
      setSearchOptionsAvailable([].concat(...Object.values(searchOptionsData)).slice(0, showCount));
    }
  }, [location, searchOptionsData, showCount]);

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper
          ref={handleRef}
          className={focused ? 'focused' : ''}
          listing={listing}
        >
          {value.map((option, index) => (
            <StyledTag label={option.title} {...getTagProps({ index })} />
          ))}
          <input
            {...getInputProps()}
            placeholder={
              value.length === 0 ? 'Search By Property Name or Location' : ''
            }
          />
           <SearchIcon className="searchIcon" onClick={handleSearch}/>
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox
          {...getListboxProps()}
          onScroll={(event) => {
            event.stopPropagation();
          }}
          listing={listing}
        >
          {groupedOptions.map((option, index) => {
            const optionProps = getOptionProps({ option, index });

            const isOptionSelected = value.some(
              (selected) => selected.title === option.title
            );

            optionProps.style = {
              ...optionProps.style,
              fontWeight: 'isOptionSelected ? bold : undefined',
            };

            return (
              <li {...optionProps}>
                <span>
                  {option.type === 'property' && (
                    <RoofingOutlinedIcon
                      style={{ marginRight: !listing ? '35px' : '15px' }}
                    />
                  )}
                  {option.type === 'locality' && (
                    <MapOutlinedIcon
                      style={{ marginRight: !listing ? '35px' : '15px' }}
                    />
                  )}
                  {option.type === 'subLocality' && (
                    <RoomOutlinedIcon
                      style={{ marginRight: !listing ? '35px' : '15px' }}
                    />
                  )}
                  {option.title}
                </span>
                <StyledType
                  style={{ color: isOptionSelected ? '#ffc107' : '' }}
                >
                  {option.type === 'subLocality' ? 'sub-locality' : option.type}
                </StyledType>
              </li>
            );
          })}
        </Listbox>
      ) : null}
    </Root>
  );
}

// const searchOptionsData = {
//   Gurgaon: [
//     { title: 'Tulip Monsella', type: 'property' },
//     { title: 'Bestech Altura', type: 'property' },
//     { title: 'BPTP Terra', type: 'property' },
//     { title: 'Godrej Icon', type: 'property' },
//     { title: 'Saan Verdante', type: 'property' },
//     { title: 'Sector 53', type: 'subLocality' },
//     { title: 'Sector 79', type: 'subLocality' },
//     { title: 'Sector 37D', type: 'subLocality' },
//     { title: 'Sector 88A', type: 'subLocality' },
//     { title: 'SPR Road', type: 'locality' },
//     { title: 'Gold Course Extension Road', type: 'locality' },
//     { title: 'New Gurgaon', type: 'locality' },
//     { title: 'Dwarka Expresss Way', type: 'locality' },
//   ],
//   Noida: [
//     { title: 'Prateek Canary', type: 'property' },
//     { title: 'Kalpataru Vista', type: 'property' },
//     { title: 'TATA Eureka Park', type: 'property' },
//     { title: 'Godrej Tropical Isle', type: 'property' },
//     { title: 'M3M The Cullinan', type: 'property' },
//     { title: 'Sector 44', type: 'subLocality' },
//     { title: 'Sector 150', type: 'subLocality' },
//     { title: 'Sector 137', type: 'subLocality' },
//     { title: 'Sector 144', type: 'subLocality' },
//     { title: 'Noida Extension', type: 'locality' },
//     { title: 'Central Noida', type: 'locality' },
//     { title: 'Noida Expressway', type: 'locality' },
//     { title: 'Jaypee Town', type: 'locality' },
//   ],
//   Pune: [
//     { title: 'Godrej Woodsville', type: 'property' },
//     { title: 'Godrej Hillside 3', type: 'property' },
//     { title: 'Vilas Javdekar Indilife', type: 'property' },
//     { title: 'Vilas Javdekar Palladio', type: 'property' },
//     { title: 'VTP Earth one', type: 'property' },
//     { title: 'Keshav Nagar', type: 'locality' },
//     { title: 'NIBM Road', type: 'locality' },
//     { title: 'Pashan', type: 'locality' },
//     { title: 'Mahalunge', type: 'locality' },
//     { title: 'Kharadi', type: 'locality' },
//   ],
//   Delhi: [
//     { title: 'DLF Mid Town', type: 'property' },
//     { title: 'Sector 53', type: 'subLocality' },
//     { title: 'Sector 80', type: 'subLocality' },
//     { title: 'Sector 60', type: 'subLocality' },
//     { title: 'Sector 50', type: 'subLocality' },
//     { title: 'Sector 3', type: 'subLocality' },
//     { title: 'Vasant Kunj', type: 'locality' },
//     { title: 'Defense Colony', type: 'locality' },
//     { title: 'Shanti Niketan', type: 'locality' },
//     { title: 'Chanakyapuri', type: 'locality' },
//     { title: 'Golf Links', type: 'locality' },
//   ],
// };
