import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import Button from '../../../common/button/button';
import Wishlist from '../../../common/wishlist/wishlist';
import Mapho from '../../../maps/maps';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import Pagination from '@mui/material/Pagination';
import { useDispatch } from 'react-redux';
import { getPropertyAll } from '../../../reduxSlices/propertyListingSlice';
import './propertyListingCard.css';
import { clearProperty } from '../../../reduxSlices/propertySlice';
import { searchNames } from '../../../services/search.service';

import { useLocation } from 'react-router-dom';

const propertyListCardStyles = {
  paper: {
    width: '100%',
    height: '32vh',
    marginBottom: '20px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2% 2%',
    overflow: 'hidden',
  },
  imageChip: {
    position: 'absolute',
    background: '#DCAA4C',
    width: '30%',
    borderRadius: '5px',
    left: '0%',
    top: '0%',
    color: '#fff',
    fontSize: '15px',
    fontWeight: '700',
  },
  featuredChip: {
    position: 'absolute',
    background: '#0CA92E',
    width: '30%',
    borderRadius: '5px',
    left: '0%',
    top: '0%',
    color: '#fff',
    fontSize: '15px',
    fontWeight: '700',
  },
};

function PropertyListingCard({ name, type, onOpenBackdrop }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [mapCenter, setMapCenter] = useState({ lat: 28.4595, lng: 77.0266 });
  const [currentZoom, setCurrentZoom] = useState(12);
  const [fetchedProperties, setFetchedProperties] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleViewMorePropertyClick = (id) => {
    navigate(`/property/${id}`);
  };
 
  const SearchNames = JSON.parse(localStorage.getItem('SearchNames') || '[]');
  // console.log(SearchNames);

  // useEffect(() => {
  //   if (SearchNames) {
  //     dispatch(SearchNames);
  //   }
  // }, [dispatch]);

  const projects = useSelector((state) => state.searchAll);
  const projects1 = useSelector((state)=> state.searchData)

  const projects2 = useSelector((state)=> state.propertyCityListing?.data?.data)
  // console.log("Projects1 DaTA",projects1);


  useEffect(() => {
    // Scroll to top on URL change
    window.scrollTo(0, 0);

    // Update properties based on the route
    if (projects2?.cities?.length > 0) {
      setFetchedProperties(projects2.cities[0]?.properties);
      setTotalPages(Math.ceil(projects1.results.productCount / 10));
    } else {
      setFetchedProperties([]);
      setTotalPages(1);
    }
  }, [location.pathname, projects2, projects1]);

  


  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setFetchedProperties([]);
    dispatch(clearProperty());
    dispatch(getPropertyAll({ type, name, page }));
  };

  return (
    <>
      <div className="propertyListingCardWrapper">
        <div className="propertyListPageSectionFlex">
          <div className="propertyListPageLeftSection">
          <h3>Properties In {fetchedProperties?.[0]?.city?.name || ""}</h3>
            {fetchedProperties && fetchedProperties.length > 0 &&
              fetchedProperties.map((prop) => {
                return (
                  <Paper
                    sx={propertyListCardStyles.paper}
                    elevation={8}
                    onMouseEnter={() => {
                      setMapCenter(prop.coordinates);
                      setCurrentZoom(17);
                    }}
                    key={prop._id}
                  >
                    <div className="propertyListingCardLeftSection">
                      <img
                        className="propertyListingCardLeftSectionImg"
                        src={prop.imageGallery[0].url}
                        alt="featured"
                      />
                      {prop.exclusive && (
                        <Chip
                          sx={propertyListCardStyles.imageChip}
                          label="Exclusive"
                          Filled
                        />
                      )}
                      {prop.featured && (
                        <Chip
                          sx={propertyListCardStyles.featuredChip}
                          label="Featured"
                          Filled
                        />
                      )}
                    </div>
                    <div className="propertyListingCardRightSection">
                      <div className="propertyListingCardHeaderFlex">
                        <h4>{prop.name}</h4>
                        {/* <Wishlist property={prop._id} /> */}
                      </div>
                      <p className="propertyListingCardRightSectionLocation">
                        {prop.subLocality?.name}, {prop.locality?.name},{' '}
                        {prop.city?.name}
                      </p>
                      <div className="propertyListingCardRightSectionGridContainer">
                        <p>
                          Price:{' '}
                          <span
                            style={{
                              color: '#337ab7',
                              fontWeight: '500',
                              marginLeft: '5px',
                            }}
                          >
                            {prop.startingPrice}
                          </span>
                        </p>
                        <p>
                          Config:{' '}
                          <span
                            style={{
                              color: '#337ab7',
                              fontWeight: '500',
                              marginLeft: '5px',
                            }}
                          >
                            {prop.configuration}
                          </span>
                        </p>
                        <p>
                          Area:{' '}
                          <span
                            style={{
                              color: '#337ab7',
                              fontWeight: '500',
                              marginLeft: '5px',
                            }}
                          >
                            {prop.area.slice(0, 11)}
                          </span>
                        </p>
                        <p>
                          Posession:{' '}
                          <span
                            style={{
                              color: '#337ab7',
                              fontWeight: '500',
                              marginLeft: '5px',
                            }}
                          >
                            {prop.possesion}
                          </span>
                        </p>
                      </div>
                      <p className="propertyListingCardRightSectionDescription">
                        " {prop.tagLine} "
                      </p>
                      <div className="propertyListingCardRightSectionBtnContainer">
                        <Button
                          btnText="View More"
                          padding="7px 15px"
                          otherStyles={{
                            background: '#0CA92E',
                            marginRight: '15px',
                          }}
                          onClick={() => handleViewMorePropertyClick(prop.slug)}
                        />
                        <Button
                          btnText="Enquire Now"
                          padding="7px 15px"
                          onClick={() => onOpenBackdrop(prop.name)}
                        />
                      </div>
                    </div>
                  </Paper>
                );
              })}
            <div className="propertyPageListingPaginationWrapper">
              {/* <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                color="primary"
              /> */}
            </div>
          </div>
          <div className="propertyListPageRightSection">
            <Mapho center={mapCenter} zoom={currentZoom} />
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyListingCard;
