import React, { useState, useRef, useEffect } from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SocialIcon } from 'react-social-icons';
import useMediaQuery from '../../utils/useMediaQuery';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaLinkedin, FaPinterest } from 'react-icons/fa';
import './footer.css';

function Footer() {
  const [openDropDown, setOpenDropdown] = useState('');
  const isDesktop = useMediaQuery(786);
  const footerRef = useRef(null);
  useEffect(() => {
    const updateFooterPosition = () => {
      const footerTop = footerRef.current.getBoundingClientRect().top + window.scrollY;
      const footerHeight = footerRef.current.offsetHeight;
      window.dispatchEvent(new CustomEvent('footerPosition', {
        detail: { footerTop, footerHeight }
      }));
    };

    updateFooterPosition(); // Initial position
    window.addEventListener('resize', updateFooterPosition);

    return () => {
      window.removeEventListener('resize', updateFooterPosition);
    };
  }, []);

  const handleFooterDropdownClick = (index) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };
  return (
    <div className="footerWrapper" id="footer" ref={footerRef}>
      <div className="footerFirstSectionContainer">
        <div className="footerFirstSectionCompanyDetailsWrapper">
          <img
            src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/inframantraLogo.png"
            alt="Infra logo white"
          />
          <div className="footerFirstSectionCompanyDetailsFlex">
            <PlaceIcon
              sx={{
                color: '#E7B554',
                fontSize: '25px',
                marginRight: '10px',
              }}
            />
            <p>95, Institutional Area, Sector 32, Gurugram</p>
          </div>
          <div className="footerFirstSectionCompanyDetailsFlex">
            <LocalPhoneIcon
              sx={{
                color: '#E7B554',
                fontSize: '25px',
                marginRight: '10px',
              }}
            />
            <p>+91 86 9800 9900 </p>
          </div>
          <div className="footerFirstSectionCompanyDetailsFlex">
            <MailIcon
              sx={{
                color: '#E7B554',
                fontSize: '25px',
                marginRight: '10px',
              }}
            />
            <p>marketing@inframantra.com </p>
          </div>
        </div>
        {isDesktop && (
          <>
            <div className="footerSecondSectionCompanyDetailsWrapper">
              <h4>INFRAMANTRA</h4>
              <p><a href='/'>Home</a></p>
              <p><a href='/about-us'>About Us</a></p>
              <p><a href='/our-services'>Services</a></p>
              <p><a href="/testimonials">Testimonials</a></p>
              <p><a href='/careers'>Careers</a></p>
              <p><a href='/blog'>Media And Blogs</a></p>
              <p><a href='/contact-us'>Contact Us</a></p>
            </div>
            <div className="footerThirdSectionCompanyDetailsWrapper">
              <h4>Quick Search</h4>
              <div className="footerThirdSectionDropdownFlex">
                <div className="footerThirdSectionDropdownHeaderFlex">
                  <p>Top Properties In Gurgaon</p>
                  <ArrowDropDownIcon
                    onClick={() => handleFooterDropdownClick(1)}
                  />
                </div>
                {openDropDown === 1 && (
                  <div className="footerThirdSectionDropdownLinkWrapper">
                    <p><a href='/property/vatika-seven-elements-sector-89a-gurgaon'>Vatika Seven Elements</a></p>
                    <p><a href='/property/vatika-sovereign-park-sector-99-gurgaon'>Vatika Sovereign Park</a></p>
                    <p><a href='/property/dlf-arbour-sector-63-gurgaon'>DLF The Arbour</a></p>
                    <p><a href="/property/ambience-creacions-sector-22-gurugram">Ambience Creacion</a></p>
                    <p><a href='/property/bptp-terra-sector-37d-gurgaon'>BPTP Terra</a></p>
                  </div>
                )}
              </div>

              <div className="footerThirdSectionDropdownFlex">
                <div className="footerThirdSectionDropdownHeaderFlex">
                  <p>Top Properties In Pune</p>
                  <ArrowDropDownIcon
                    onClick={() => handleFooterDropdownClick(2)}
                  />
                </div>
                {openDropDown === 2 && (
                  <div className="footerThirdSectionDropdownLinkWrapper">
                    <p><a href='/property/gera-island-of-joy-kharadi-pune'>Gera Island of Joy</a></p>
                    <p><a href='/property/lodha-estilo-kharadi-pune'>Lodha Estilo</a></p>
                    <p><a href='/property/pristine-o2-world-kharadi-pune'>Pristine O2 World</a></p>
                    <p><a href='/property/kalpataru-vista-sector-128-noida'>Kalpataru Vista</a></p>
                    <p><a href='/property/krisumi-waterfall-sector-36a-gurgaon'>Krisumi Waterfall Residences</a></p>
                  </div>
                )}
              </div>
              <div className="footerThirdSectionDropdownFlex">
                <div className="footerThirdSectionDropdownHeaderFlex">
                  <p>Top Properties In Noida</p>
                  <ArrowDropDownIcon
                    onClick={() => handleFooterDropdownClick(3)}
                  />
                </div>
                {openDropDown === 3 && (
                  <div className="footerThirdSectionDropdownLinkWrapper">
                    <p><a href='/property/tata-eureka-park-sector-150-noida'>TATA Eureka Park</a></p>
                    <p><a href='/property/m3m-the-cullinan-sector-94-noida'>M3M The Cullinan</a></p>
                    <p><a href='/property/kalpataru-vista-sector-128-noida'>Kalpataru Vista</a></p>
                    <p><a href='/property/prateek-canary-sector-150-noida'>Prateek Canary</a></p>
                    {/* <p>Tulip Monsella</p> */}
                  </div>
                )}
              </div>
              <div className="footerThirdSectionDropdownFlex">
                <div className="footerThirdSectionDropdownHeaderFlex">
                  <p>Premium Properties</p>
                  <ArrowDropDownIcon
                    onClick={() => handleFooterDropdownClick(4)}
                  />
                </div>
                {openDropDown === 4 && (
                  <div className="footerThirdSectionDropdownLinkWrapper">
                    <p><a href='/property/vatika-seven-elements-sector-89a-gurgaon'>Vatika Seven Elements</a></p>
                    <p><a href='/property/vatika-sovereign-park-sector-99-gurgaon'>Vatika Sovereign Park</a></p>
                    <p><a href='/property/dlf-arbour-sector-63-gurgaon'>DLF The Arbour</a></p>
                    <p><a href='/property/gera-island-of-joy-kharadi-pune'>Gera Island of Joy</a></p>
                    {/* <p>Tulip Monsella</p> */}
                  </div>
                )}
              </div>
              <div className="footerThirdSectionDropdownFlex">
                <div className="footerThirdSectionDropdownHeaderFlex">
                  <p>Exclusive Properties</p>
                  <ArrowDropDownIcon
                    onClick={() => handleFooterDropdownClick(5)}
                  />
                </div>
                {openDropDown === 5 && (
                  <div className="footerThirdSectionDropdownLinkWrapper">
                    <p><a href='/property/vatika-seven-elements-sector-89a-gurgaon'>Vatika Seven Elements</a></p>
                    <p><a href='/property/vatika-sovereign-park-sector-99-gurgaon'>Vatika Sovereign Park</a></p>
                    <p><a href='/property/signature-global-city-37d-gurgaon'>Signature Global City Sector 37D</a></p>
                    <p><a href='/property/orchid-ivy-floors-sector-51-gurgaon'>Orchid IVY Floors</a></p>
                    <p><a href='/property/tulip-monsella-sector-53-gurgaon'>Tulip Monsella</a></p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!isDesktop && (
          <div className="footerSecondSectionMobileContainer">
            <div className="footerSecondSectionCompanyDetailsWrapper">
              <h4>INFRAMANTRA</h4>
              <p><a href='/'>Home</a></p>
              <p><a href='/about-us'>About Us</a></p>
              <p><a href='/our-services'>Services</a></p>
              <p><a href="/testimonials">Testimonials</a></p>
              <p><a href='/careers'>Careers</a></p>
              <p><a href='/blog'>Media And Blogs</a></p>
              <p><a href='/contact-us'>Contact Us</a></p>
            </div>
            <div className="footerThirdSectionCompanyDetailsWrapper">
              <h4>Quick Search</h4>
              <div className="footerThirdSectionDropdownFlex">
                <div className="footerThirdSectionDropdownHeaderFlex">
                  <p>Top Properties In Gurgaon</p>
                  <ArrowDropDownIcon
                    onClick={() => handleFooterDropdownClick(1)}
                  />
                </div>
                {openDropDown === 1 && (
                  <div className="footerThirdSectionDropdownLinkWrapper">
                    <p><a href='/property/vatika-seven-elements-sector-89a-gurgaon'>Vatika Seven Elements</a></p>
                    <p><a href='/property/vatika-sovereign-park-sector-99-gurgaon'>Vatika Sovereign Park</a></p>
                    <p><a href='/property/dlf-arbour-sector-63-gurgaon'>DLF The Arbour</a></p>
                    <p><a href="/property/ambience-creacions-sector-22-gurugram">Ambience Creacion</a></p>
                    <p><a href='/property/bptp-terra-sector-37d-gurgaon'>BPTP Terra</a></p>
                  </div>
                )}
              </div>

              <div className="footerThirdSectionDropdownFlex">
                <div className="footerThirdSectionDropdownHeaderFlex">
                  <p>Top Properties In Pune</p>
                  <ArrowDropDownIcon
                    onClick={() => handleFooterDropdownClick(2)}
                  />
                </div>
                {openDropDown === 2 && (
                  <div className="footerThirdSectionDropdownLinkWrapper">
                    <p><a href='/property/gera-island-of-joy-kharadi-pune'>Gera Island of Joy</a></p>
                    <p><a href='/property/lodha-estilo-kharadi-pune'>Lodha Estilo</a></p>
                    <p><a href='/property/pristine-o2-world-kharadi-pune'>Pristine O2 World</a></p>
                    <p><a href='/property/kalpataru-vista-sector-128-noida'>Kalpataru Vista</a></p>
                    <p><a href='/property/krisumi-waterfall-sector-36a-gurgaon'>Krisumi Waterfall Residences</a></p>
                  </div>
                )}
              </div>
              <div className="footerThirdSectionDropdownFlex">
                <div className="footerThirdSectionDropdownHeaderFlex">
                  <p>Top Properties In Noida</p>
                  <ArrowDropDownIcon
                    onClick={() => handleFooterDropdownClick(3)}
                  />
                </div>
                {openDropDown === 3 && (
                  <div className="footerThirdSectionDropdownLinkWrapper">
                    <p><a href='/property/tata-eureka-park-sector-150-noida'>TATA Eureka Park</a></p>
                    <p><a href='/property/m3m-the-cullinan-sector-94-noida'>M3M The Cullinan</a></p>
                    <p><a href='/property/kalpataru-vista-sector-128-noida'>Kalpataru Vista</a></p>
                    <p><a href='/property/prateek-canary-sector-150-noida'>Prateek Canary</a></p>
                    {/* <p>Tulip Monsella</p> */}
                  </div>
                )}
              </div>
              <div className="footerThirdSectionDropdownFlex">
                <div className="footerThirdSectionDropdownHeaderFlex">
                  <p>Premium Properties</p>
                  <ArrowDropDownIcon
                    onClick={() => handleFooterDropdownClick(4)}
                  />
                </div>
                {openDropDown === 4 && (
                  <div className="footerThirdSectionDropdownLinkWrapper">
                    <p><a href='/property/vatika-seven-elements-sector-89a-gurgaon'>Vatika Seven Elements</a></p>
                    <p><a href='/property/vatika-sovereign-park-sector-99-gurgaon'>Vatika Sovereign Park</a></p>
                    <p><a href='/property/dlf-arbour-sector-63-gurgaon'>DLF The Arbour</a></p>
                    <p><a href='/property/gera-island-of-joy-kharadi-pune'>Gera Island of Joy</a></p>
                    {/* <p>Tulip Monsella</p> */}
                  </div>
                )}
              </div>
              <div className="footerThirdSectionDropdownFlex">
                <div className="footerThirdSectionDropdownHeaderFlex">
                  <p>Exclusive Properties</p>
                  <ArrowDropDownIcon
                    onClick={() => handleFooterDropdownClick(5)}
                  />
                </div>
                {openDropDown === 5 && (
                  <div className="footerThirdSectionDropdownLinkWrapper">
                    <p><a href='/property/vatika-seven-elements-sector-89a-gurgaon'>Vatika Seven Elements</a></p>
                    <p><a href='/property/vatika-sovereign-park-sector-99-gurgaon'>Vatika Sovereign Park</a></p>
                    <p><a href='/property/signature-global-city-37d-gurgaon'>Signature Global City Sector 37D</a></p>
                    <p><a href='/property/orchid-ivy-floors-sector-51-gurgaon'>Orchid IVY Floors</a></p>
                    <p><a href='/property/tulip-monsella-sector-53-gurgaon'>Tulip Monsella</a></p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="footerForthSectionCompanyDetailsWrapper">
          <h4>Follow Us</h4>
          {isDesktop && (
            <>
              <div className="footerForthSectionIconWrapper">
                <SocialIcon url="https://www.facebook.com/inframantraofficial" style={{ height: '40px', width: '40px' }} />
                <SocialIcon url="https://x.com/INFRAMANTRA_" style={{ height: '40px', width: '40px' }} />
                <SocialIcon url="https://www.instagram.com/inframantraofficial/" style={{ height: '40px', width: '40px' }} />
              </div>
              <div className="footerForthSectionIconWrapper">
                <SocialIcon url="https://www.youtube.com/@inframantraofficial" style={{ height: '40px', width: '40px' }} />
                <SocialIcon url="https://in.linkedin.com/company/inframantra" style={{ height: '40px', width: '40px' }} />
                <SocialIcon url="https://in.pinterest.com/inframantraofficial/" style={{ height: '40px', width: '40px' }} />
              </div>
            </>
          )}
          {!isDesktop && (
            <div className="footerForthSectionIconWrapper">
              <SocialIcon url="https://www.facebook.com/inframantraofficial" style={{ height: '40px', width: '40px' }} />
              <SocialIcon url="https://x.com/INFRAMANTRA_" style={{ height: '40px', width: '40px' , backdropFilter: "#FFF"}} />
              <SocialIcon url="https://www.instagram.com/inframantraofficial/" style={{ height: '40px', width: '40px' }} />
              <SocialIcon url="https://www.youtube.com/@inframantraofficial" style={{ height: '40px', width: '40px' }} />
              <SocialIcon url="https://in.linkedin.com/company/inframantra" style={{ height: '40px', width: '40px' }} />
              <SocialIcon url="https://in.pinterest.com/inframantraofficial/" style={{ height: '40px', width: '40px' }} />
            </div>
          )}
        </div>
      </div>
      <div className="footerSecondSectionContainer">
        {isDesktop && (
          <>
            <div className="footerSecondSectionLinksFlex">
              <p><a href='/page/terms-conditions'>Terms And Conditions</a></p>
              <p><a href='/page/privacy-policy'>Privacy Policy</a></p>
              <p><a href='/page/user-agreement'>User Agreement</a></p>
              <p><a href='/page/disclaimer'>Disclaimer</a></p>
            </div>
            <div className="footerSecondSectionCopyRightFlex">
              <p>Copyright @ 2024 Inframantra</p>
              <p>All Rights Reserved</p>
            </div>
          </>
        )}
        {!isDesktop && (
          <>
            <div className="footerSecondSectionLinksFlex">
              <p><a href='/page/terms-conditions'>Terms And Conditions</a></p>
              <p><a href='/page/privacy-policy'>Privacy Policy</a></p>
              <p><a href='/page/user-agreement'>User Agreement</a></p>
              <p><a href='/page/disclaimer'>Disclaimer</a></p>
            </div>
            <div className="footerSecondSectionCopyRightFlex">
              <p>Copyright @ 2024 Inframantra </p>
              <p>All Rights Reserved</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Footer;
