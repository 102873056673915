import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/homePage/homePage";
import NavigationBar from "./components/navigationBar/navigationBar";
import PropertyPage from "./pages/propertyPage/propertyPage";
import PropertyListingPage from "./pages/propertyListingPage/propertyListingPage";
import PropertyListingPage1 from "./pages/propertyListing/propertyListingPage";
import AboutUsPage from "./pages/aboutUsPage/aboutUsPage";
import ContactUsPage from "./pages/contactUsPage/contactUsPage";
import TestiMonials from "./pages/testimonials/testimonials";
import ServicesPage from './pages/servicesPage/servicesPage';
import NotFoundPage from './pages/404NotFound/404NotFound';
import Footer from './components/footer/footer';
import BlogPage from './pages/blogPage/blogPage.jsx';
import BlogIndexPage from './pages/blogPage/index.jsx';
import PagesPage from './pages/termsAndCondition/termsAndCondition.jsx';
// V2
import PropertyListingPage2 from "./pages/propertyStatePage/propertyListingPage.jsx";
import Thankyou from "./pages/thankYou/thankyou.jsx";
import PropertyPage1 from './testPages/propertyPage1.jsx';
import TestHomePage from './testPages/testHome.js';
import BlogHomePage from './pages/blogPageMain/index.js';



import Loader from "./components/UI/customLoader.jsx";


import Wrapper from "./components/UI/Wrapper.jsx";

import "./App.css";

function App() {
  const location = useLocation();
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const isHomePage = location.pathname === "/";

  return (
    <div className='App'>
      {/* <NavigationBar pageBgd={!isHomePage} /> */}
      {/* <Wrapper> */}
        <Routes>
        <Route path='/' element={<HomePage />} />
        {/* <Route path='/property/:id' element={<PropertyPage />} /> */}
        <Route path="/property/:slug" element={<PropertyPage />} />
        <Route path='/property-listing/:type/:name' element={<PropertyListingPage />} />
        <Route path='/listing' element={<PropertyListingPage1 />} />
        <Route path='/about-us' element={<AboutUsPage />} />
        <Route path='/contact-us' element={<ContactUsPage />} />
        <Route path="/testimonials" element={<TestiMonials/>} />
        <Route path="/our-services" element={<ServicesPage />} />
        <Route path='/blog' element={<BlogPage />} />
        <Route path='/blog/:category/:slug' element={<BlogPage />} />
        <Route path='/blog/:slug' element={<BlogIndexPage />} />
        <Route path='/page/:slug' element={<PagesPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/wrapper" element={<Wrapper />} />
        {/* V2 */}
        <Route path="/loader" element={<Loader />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="/property-in-gurgaon" element={<PropertyListingPage2 />} />
        <Route path="/property-in-pune" element={<PropertyListingPage2 />} />
        <Route path="/property-in-noida" element={<PropertyListingPage2 />} />
        <Route path="/blogs" element={<BlogHomePage/>} />
      </Routes>
      {/* </Wrapper> */}
      {/* <Footer /> */}
    </div>
  );
}


export default App;
