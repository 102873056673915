import React, { useEffect, useState } from 'react';
import PropertyListingCard from './propertyListingCard/propertyListingCard.jsx';
import PropertyListingDesktopNav from './propertyListingDesktopNav/propertyListingDesktopNav';
import PropertyListingCardMobile from './propertyListingCardMobile/propertyListingCardMobile';
import useMediaQuery from '../../utils/useMediaQuery';
import { getPropertyCityListing } from '../../reduxSlices/propertyCityListing.js';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CustomBackdrop from '../../components/backdrop/backdrop';
import Wrapper from '../../components/UI/Wrapper.jsx';
import PropertyPageFloatingContact from '../propertyPage/propertyPageSections/propertyPageFloatingContact';

function PropertyListingPage() {
  const isDesktop = useMediaQuery(768);
  const dispatch = useDispatch();
  const { type, name } = useParams();
  const location = useLocation();

  const segments = location.pathname.split('/');
  const lastSegment = segments.pop(); // Gets 'property-in-gurgaon'
  const stateName = lastSegment.split('-').pop();

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [selectedPropertyName, setSelectedPropertyName] = useState('');

  const handleClose = () => {
    setBackdropOpen(false);
    setSelectedPropertyName('');
  };

  const handleOpen = (name) => {
    setSelectedPropertyName(name);
    setBackdropOpen(true);
  };

  useEffect(() => {
    const stateNamesToMatch = ['gurgaon', 'delhi', 'pune']; // Add the state names you want to match
  
    if (stateName) {
      dispatch(getPropertyCityListing({ stateName }));
    }
  }, [dispatch, stateName, location.pathname]);
  return (
    <Wrapper>
      {isDesktop ? (
        <>
          <PropertyListingDesktopNav />
          <PropertyListingCard
            state={stateName} // Ensure stateName is passed
            name={decodeURIComponent(name)}
            type={type}
            onOpenBackdrop={handleOpen}
          />
        </>
      ) : (
        <PropertyListingCardMobile onOpenBackdrop={handleOpen} />
      )}
      <CustomBackdrop open={backdropOpen} onClose={handleClose}>
        <PropertyPageFloatingContact name={selectedPropertyName} />
      </CustomBackdrop>
    </Wrapper>
  );
}

export default PropertyListingPage;
